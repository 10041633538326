<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardBody style="min-height: 400px; padding: 0px !important">
            <CRow>
              <CCol lg="4" md="4" style="margin-right: 0px; padding-right: 0px">
                <CListGroup style="height:400px !important;overflow-y:auto">
                  <CListGroupItem
                    href="#"
                    class="flex-column align-items-start"
                    v-for="c in tabs"
                    v-bind:key="c.username"
                    @click.prevent="getChat(c.username)"
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-0">{{ c.nama_lengkap }}</h5>
                      <small>Online</small>
                    </div>
                    <small>{{ c.username }}</small>
                  </CListGroupItem>
                </CListGroup>
              </CCol>
              <CCol
                lg="8"
                md="8"
                style="
                  padding-left: 0px !important;
                  margin-left: 0px !important;
                "
              >
                <div
                  style="
                    margin: 0px 0px 0px 0px;
                    min-height: 400px;
                    background: #f3f3f3;
                  "
                >
                  <div
                    style="
                      padding: 10px;
                      vertical-align: bottom;
                      height: 360px;
                      width: 100%;
                      max-width: 100%;
                    "
                  >
                    <div
                      v-html="pesan"
                      style="overflow:auto;align-self: flex-end;display;inline-block;width:100%;height:360px !important;max-height:400px"
                    ></div>
                  </div>
                  <CInput
                    style="
                      position: absolute;
                      bottom: 0px;
                      left: 10px;
                      right: 10px;
                      width: 95%;
                    "
                    type="text"
                    v-model="mssg"
                    placeholder="Pesan Anda..."
                    v-on:keyup.enter="inputChat"
                  >
                    <template #append>
                      <CButton
                        type="button"
                        v-on:click="openFile"
                        color="primary"
                        ><CIcon name="cil-image"/></CButton
                      >
                      <input id="file-input" type="file" accept="image/*" name="name" style="display: none;" />
                      <CButton
                        type="button"
                        v-on:click="inputChat"
                        color="primary"
                        >Kirim</CButton
                      >
                    </template>
                  </CInput>
                </div>
              </CCol>
            </CRow>
            <input type="hidden" v-model="sedr" />
            <input type="hidden" v-model="rcvr" />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import io from "socket.io-client";
import MD5 from "crypto-js/md5";
import axios from "axios";
export default {
  name: "Tabs",
  data() {
    return {
      tabs: [],
      rooms : [],
      pesan: " ",
      mssg: "",
      sedr: "superadmin",
      rcvr: "",
      activeTab: 1,
      socket : io("http://localhost:3001"),
      user: JSON.parse(localStorage.getItem("user")),
      roomid: "",
    };
  },
  mounted() {
    this.showUser();
    // this.socket = io("http://localhost:3001");
    
  },
  created() {
    this.getRealtime();
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    openFile(){
      document.getElementById('file-input').click();
    },
    getRealtime(){
      this.socket.on("MESSAGE", (data) => {
        // this.pesan = data;
        // const fruits = ["Banana", "Orange", "Apple", "Mango"];
        // fruits.includes("Mango");
          // alert(data.receiver);
        if(data.receiver==this.user.id){
            // this.notify(data.pesansingle, data.receiver);
            // this.messages.push(data);
            this.getChat(data.receiver);
        }
        // you can also do this.messages.push(data)
      });
    },
    notify (pesan, receiver) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification#Parameters
      const notification = {
        title: 'Pesan Baru',
        options: {
          body: pesan
        },
        events: {
          onclick: function () {
              // this.getChat(receiver);
              console.log('Custom click event was called');
          },
        }
      }
      this.$notification.show(notification.title, notification.options, notification.events)
    },
    showUser: function (event) {
      let username = window.btoa(this.user.id);
      // Simple POST request with a JSON body using axios
      // alert(username);
      const pekerjaan = {
        nama: this.nama,
        kode: this.kode,
        kegiatan: this.kegiatan,
      };
      // alert(JSON.stringify(pekerjaan));
      axios
        .get(
          "https://sippd.probowsolution.com/public/usermessenger/" +
            username +
            "?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu"
        )
        .then((response) => {
          // console.log(response);
          // alert(response.data);
          this.tabs = response.data;
          response.data.forEach(element => {
              // ...use `element`...
              // console.log("username :"+element.username);
              this.rooms.push(element.username);
          });
        });
    },
    getChat: function (receiver) {
      let username = window.btoa(this.user.id);
      // Simple POST request with a JSON body using axios
      // alert(receiver);
      // alert(JSON.stringify(pekerjaan));
      this.rcvr = receiver;
      axios
        .get(
          "https://sippd.probowsolution.com/public/messenger/" +
            username +
            "/" +
            window.btoa(receiver) +
            "?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu"
        )
        .then((response) => {
          // console.log(response);
          // alert(response.data);
          // this.tabs = response.data;
          this.pesan = response.data;
          // this.roomid = response.data[0].room;
          // alert(response.data[0].room);
          axios
            .get(
              "https://sippd.probowsolution.com/public/messengerjson/" +
                username +
                "/" +
                window.btoa(receiver) +
                "?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu"
            )
            .then((response2) => {
              if (this.roomid != response2.data.room) {
                // this.socket.on("connect", function () {
                  // const sessionID = this.socket.socket.sessionid; //
                  // if(this.roomid)!
                  // this.socket.join(response2.data.room);
                  // if()
                  this.socket.emit("join",  response2.data.room);
                  this.roomid = response2.data.room;
                  // console.log("room-id : "+this.roomid+" room :"+response2.data.room );
                  // console.log("room_id : " + this.roomid);
                // });
              }
            });
        });
    },
    inputChat: function (event) {
      let username = this.user.id;
      // Simple POST request with a JSON body using axios
      const chat = {
        sender: username,
        receiver: this.rcvr,
        message: this.mssg,
        room: this.roomid,
      };
      // alert(JSON.stringify(chat));

      axios
        .post(
          "https://sippd.probowsolution.com/public/addchat",
          chat
        )
        .then((response) => {
          // console.log(response);
          // alert(response.data);
          if (response.data == "sukses") {
            const today = new Date();
            const date =
              today.getDate() +
              "/" +
              (today.getMonth() + 1) +
              "/" +
              today.getFullYear();
            const time =
              today.getHours() +
              ":" +
              today.getMinutes() +
              ":" +
              today.getSeconds();
            const dateTime = date + " " + time;
            let chat =
              "<div class='row'><div class='col-md-6 col-lg-6'> <div class='card bg-success text-white'>       <div class='card-body'> " +
              this.mssg +
              "<br><small>" +
              dateTime +
              "</small></div> </div></div><div class='col-md-6 col-lg-6'>&nbsp;</div></div>";
            // this.$router.push('/kontraktor');
            this.pesan += chat;
            axios
              .get(
                "https://sippd.probowsolution.com/public/messenger/" +
                  username +
                  "/" +
                  window.btoa(this.rcvr) +
                  "?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu"
              )
              .then((response2) => {
                if (this.roomid != "") {
                  // this.socket.on("connect", function () {
                    // const sessionID = this.socket.socket.sessionid; //
                    // if(this.roomid)!
                    // this.socket.join(response2.data.room);
                    // this.socket.to(this.roomid).emit("SEND_MESSAGE", {
                    //   pesan: response2.data,
                    // });
                    this.socket.emit("SEND_MESSAGE",{ room: this.roomid, pesan : response2.data, pesansingle :  this.mssg, username : username, receiver : this.rcvr } );
                    // console.log("room_id : " + this.roomid+" / pesan :"+response2.data);
                    this.mssg = "";
                  // });
                }
              });
          } else {
            this.$root.$emit("bv::show::modal", "modalError");
          }
        });
    },
  },
};
</script>
